<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="'Danh sách báo cáo HH Ngành Điện Thoại (Tháng)'"
        >
          <template v-slot:preview>
            <!-- Header session -->
            <div class="col-md-12 mb-5">
              <b-form>
                <b-row>
                  <b-col style="padding-left: 0">
                    <b-input
                      placeholder="Tìm kiếm"
                      v-model="search"
                      append-icon="search"
                      single-line
                      hide-details
                      class="btn-sm"
                      v-on:input="fetchData()"
                    ></b-input>
                  </b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>
              </b-form>
            </div>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="rewardPhonePrograms"
              :fields="fields"
              sort-by="count"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template v-slot:cell(status)="row">
                <span
                  v-if="checkStatus(row.item)"
                  v-text="row.item.statusName"
                  class="
                    label
                    font-weight-bold
                    label-lg label-light-success label-inline
                  "
                ></span>
                <span
                  v-else
                  v-text="row.item.statusName"
                  class="
                    label
                    font-weight-bold
                    label-lg label-light-danger label-inline
                  "
                ></span>
              </template>
              <template v-slot:cell(createdAt)="row">
                <p class="text-center">{{ formatDate(row.item.createdAt) }}</p>
                <p class="text-center">{{ row.item.createdBy }}</p>
              </template>
              <template v-slot:cell(startDate)="row">
                <p class="text-center">{{ formatDate(row.item.startDate) }}</p>
              </template>
              <template v-slot:cell(endDate)="row">
                <p class="text-center">{{ formatDate(row.item.endDate) }}</p>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="exportReport(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem;"
                            class="flaticon2-download"
                          ></i>
                          &nbsp; Tải
                        </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số chương trình:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchData()"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { formatDate } from '../../../utils/date';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import fileDownload from '../../../utils/file-download';
import { BASE_URL } from '../../../utils/constants';
import axios from 'axios';

export default {
  data() {
    return {
      rewardPhonePrograms: [],
      onLoading: false,
      totalPages: 0,
      count: 0,
      totalItems: 0,
      page: 1,
      search: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Chương trình',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '' },
      ],
      allSelected: false,
      optionsHeader: [],
      selectedHeader: [],
      optionsHeaderDefault: [],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Báo cáo HH Ngành Điện Thoại (Tháng)',
        route: 'reward-month-phone-program',
      },
      { title: 'Danh sách báo cáo HH Ngành Điện Thoại (Tháng)' },
    ]);

    if (this.$route.params.createAccessorySuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.createAccessorySuccess = false;
    }
    if (this.$route.params.updateInstallmentSuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.updateInstallmentSuccess = false;
    }
  },
  created() {
    this.fetchData();
  },
  watch: {},
  methods: {
    formatDate: formatDate,
    exportReport: async function (item) {
      // monthReport
      const baseUrl = BASE_URL;
      const url = `${baseUrl}rewardPhone/monthReport/${item.id}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: function(progressEvent) {
          console.log(progressEvent);
          // const dataChunk = progressEvent.currentTarget.response;
          // console.log(dataChunk);
        }
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    fetchData: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (!this.search) {
        this.search = '';
      }

      const params = {
        page: this.page,
        limit: 10,
        name: this.search,
      };

      ApiService.setHeader();
      ApiService.query('/rewardPhone/monthProgram/', { params }).then((response) => {
        this.rewardPhonePrograms = [];
        this.count = 0;
        this.totalPages = response.data.data.totalPage;
        this.totalItems = response.data.data.totalRow;

        response.data.data.data.map((item) => {
          this.count++;
          const data = {
            count: this.count,
            id: item.id,
            name: item.name,
            description: item.description,
            status: item.status,
            statusName: item.status ? 'Hoạt động' : 'Khóa',
            startDate: item.startDate,
            endDate: item.endDate,
            createdBy: item.createdBy,
            createdAt: item.createdAt,
          };
          this.rewardPhonePrograms.push(data);
        });
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    checkStatus: function (item) {
      if (item.status === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
</style>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
